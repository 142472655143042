<template>
  <div class="container-fluid">
    <list-desktop
      v-if="!$isMobile()"
      :empty="empty"
      :loading="loading"
      :data="data"
      :queriedData="queriedData"
      :dataPagination="dataPagination"
      v-on:add_or_edit="addOrEdit"
      v-on:search_q="searchBack"
      v-on:details_user="details"
      v-on:load_users="loadUsers"
      v-on:delete_user="deleteUser"
      v-on:send_user_identifier="sendUserIdentifier"
      v-on:per_page_selected="nbrPerPageSelected"
    >
    </list-desktop>
    <list-mobile
      v-if="$isMobile()"
      :empty="empty"
      :loading="loading"
      :data="data"
      :queriedData="queriedData"
      :dataPagination="dataPagination"
      v-on:add_or_edit="addOrEdit"
      v-on:search_q="searchBack"
      v-on:details_user="details"
      v-on:load_users="loadUsers"
      v-on:delete_user="deleteUser"
      v-on:send_user_identifier="sendUserIdentifier"
      v-on:per_page_selected="nbrPerPageSelected"
    >
    </list-mobile>

    <mdb-modal
      centered
      size="lg"
      direction="top"
      position="top"
      :show="modalUser.show"
    >
      <form @submit.prevent="!modalUser.edit ? save() : update()">
        <mdb-modal-header>
          <mdb-modal-title>{{ modalUser.title }}</mdb-modal-title>
        </mdb-modal-header>
        <mdb-modal-body class="">
          <fieldset>
            <legend class="color-normal-reversed">Générale</legend>
            <mdb-row>
              <mdb-col sm="12" md="6">
                <mdb-input
                  wrapperClass=""
                  inputClass="border-reversed color-normal-reversed"
                  labelClass=" color-normal-reversed"
                  :validation="
                    $store.state.user.errors.firstname ? true : false
                  "
                  :invalidFeedback="$store.state.user.errors.firstname"
                  outline
                  label="Prénoms"
                  v-model="form.firstname"
                ></mdb-input>
              </mdb-col>
              <mdb-col sm="12" md="6">
                <mdb-input
                  wrapperClass=""
                  inputClass="border-reversed color-normal-reversed"
                  labelClass=" color-normal-reversed"
                  :validation="$store.state.user.errors.lastname ? true : false"
                  :invalidFeedback="$store.state.user.errors.lastname"
                  outline
                  label="Nom"
                  v-model="form.lastname"
                ></mdb-input>
              </mdb-col>
              <mdb-col sm="12" md="6">
                <mdb-input
                  wrapperClass=""
                  inputClass="border-reversed color-normal-reversed"
                  labelClass=" color-normal-reversed"
                  :disabled="
                    modalUser.edit && modalUser.content.email_verified_at
                      ? true
                      : false
                  "
                  :validation="$store.state.user.errors.email ? true : false"
                  :invalidFeedback="$store.state.user.errors.email"
                  type="email"
                  outline
                  label="Adresse mail"
                  v-model="form.email"
                ></mdb-input>
              </mdb-col>
              <mdb-col sm="12" md="6">
                <mdb-input
                  wrapperClass=""
                  inputClass="border-reversed color-normal-reversed"
                  labelClass=" color-normal-reversed"
                  v-mask="'(###) ## ### ###'"
                  placeholder="(000)00 000 000"
                  outline
                  label="Téléphone"
                  v-model="form.phone"
                ></mdb-input>
              </mdb-col>
              <mdb-col
                sm="12"
                md="6"
                v-if="!modalUser.content.is_admin_principal"
              >
                <mdb-select
                  :disabled="modalUser.content.is_admin_principal"
                  flipOnScroll
                  wrapperClass=""
                  labelClass=" color-normal-reversed"
                  caretClass="color-primary-reversed "
                  search
                  outline
                  label="Site"
                  v-model="siteOption"
                  @getValue="getSite"
                ></mdb-select>
              </mdb-col>
              <mdb-col sm="12" md="6">
                <mdb-select
                  flipOnScroll
                  wrapperClass=""
                  labelClass=" color-normal-reversed"
                  caretClass="color-primary-reversed "
                  outline
                  label="Profile"
                  v-model="roleNOption"
                  @getValue="getRoleN"
                ></mdb-select>
              </mdb-col>
              <mdb-col sm="12" md="6">
                <mdb-select
                  flipOnScroll
                  wrapperClass=""
                  labelClass=" color-normal-reversed"
                  caretClass="color-primary-reversed "
                  outline
                  label="Mode"
                  v-model="modeOption"
                  @getValue="getMode"
                ></mdb-select>
              </mdb-col>
              <mdb-col sm="12" md="6">
                <mdb-select
                  flipOnScroll
                  wrapperClass=""
                  labelClass=" color-normal-reversed"
                  caretClass="color-primary-reversed "
                  outline
                  label="Role de gestion"
                  v-model="roleOption"
                  multiple
                  @getValue="getRole"
                ></mdb-select>
              </mdb-col>
            </mdb-row>
          </fieldset>
          <fieldset
            v-if="
              $store.state.auth.user.is_admin_principal && form.role === 'admin'
            "
          >
            <legend class="color-normal-reversed">Permissions</legend>
            <mdb-row>
              <mdb-col sm="12" md="6">
                <label for="">Approbateur de plafond de crédit?</label>
                <mdb-switch
                  v-model="form.is_approval_credit_plafond"
                  offLabel="Non"
                  onLabel="Oui"
                />
              </mdb-col>
            </mdb-row>
          </fieldset>
          <fieldset>
            <legend class="color-normal-reversed">Avancé</legend>
            <mdb-row class="color-normal-reversed">
              <mdb-col sm="12" md="4">
                <label for="">Etat de l'utilisateur?</label>
                <mdb-switch
                  v-model="form.is_active"
                  offLabel="Désactivé"
                  onLabel="Activé"
                />
              </mdb-col>
              <mdb-col sm="12" md="4">
                <label for="">Archiver l'utilisateur?</label>
                <mdb-switch
                  @click.native="archivedAlert"
                  v-model="form.is_archived"
                  offLabel="Non"
                  onLabel="Oui"
                />
              </mdb-col>
            </mdb-row>
          </fieldset>
        </mdb-modal-body>
        <mdb-modal-footer>
          <mdb-btn
            size="md"
            color="primary"
            type="submit"
            :disabled="modalUser.btn"
          >
            <span v-if="!modalUser.btn">Sauvegarder</span>
            <span
              v-if="modalUser.btn"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span v-if="modalUser.btn" class="pl-2">Loading...</span>
          </mdb-btn>
          <mdb-btn size="md" outline="primary" @click="modalUser.show = false"
            >Fermer</mdb-btn
          >
        </mdb-modal-footer>
      </form>
    </mdb-modal>

    <mdb-modal centered :show="modalUserDetails.show">
      <mdb-modal-header>
        <mdb-modal-title
          class="text-primary color-primary-reversed pl-2"
          style="font-weight: 700"
        >
          <span
            v-if="modalUserDetails.content.is_active === 1"
            class="badge rounded-pill bg-success"
            style="height: 10px; width: 10px; display: inline-block"
          ></span>
          <span
            v-else
            class="badge rounded-pill bg-danger"
            style="height: 10px; width: 10px; display: inline-block"
          ></span>
          {{ modalUserDetails.title }}</mdb-modal-title
        >
      </mdb-modal-header>
      <mdb-modal-body class="px-0">
        <table class="modal-details">
          <tbody>
            <tr>
              <th>Nom & Prénoms</th>
              <td>
                <span
                  v-if="
                    (modalUserDetails.content.is_active === 1) &
                    (modalUserDetails.content.is_archived === 0)
                  "
                  class="badge rounded-pill bg-success"
                  style="height: 10px; width: 10px; display: inline-block"
                ></span>
                <span
                  v-else-if="
                    (modalUserDetails.content.is_active === 0) &
                    (modalUserDetails.content.is_archived === 0)
                  "
                  class="badge rounded-pill bg-danger"
                  style="height: 10px; width: 10px; display: inline-block"
                ></span>
                <span
                  v-else
                  class="badge rounded-pill bg-secondary"
                  style="height: 10px; width: 10px; display: inline-block"
                ></span>
                {{ modalUserDetails.content.lastname }}
                {{ modalUserDetails.content.firstname }}
              </td>
            </tr>
            <tr>
              <th>Adresse Email</th>
              <td>{{ modalUserDetails.content.email }}</td>
            </tr>
            <tr>
              <th>Téléphone</th>
              <td>{{ modalUserDetails.content.phone }}</td>
            </tr>
            <tr>
              <th>Site</th>
              <td>
                <span v-if="modalUserDetails.content.site">{{
                  modalUserDetails.content.site.name
                }}</span>
              </td>
            </tr>
            <tr>
              <th>Mode</th>
              <td>
                <span v-if="modalUserDetails.content.mode === 'advanced'">
                  Avancé</span
                >
                <span v-if="modalUserDetails.content.mode === 'simple'"
                  >Simple</span
                >
              </td>
            </tr>
            <tr>
              <th>Utilisateur archivé ?</th>
              <td>
                <span v-if="modalUserDetails.content.is_archived === 1">
                  Oui
                </span>
                <span v-else> Non </span>
              </td>
            </tr>
            <tr
              v-if="
                $store.state.auth.user.is_admin_principal &&
                modalUserDetails.content.role === 'admin'
              "
            >
              <th>Approbateur de plafond de crédit ?</th>
              <td>
                <span
                  v-if="
                    modalUserDetails.content.is_approval_credit_plafond === 1
                  "
                >
                  Oui
                </span>
                <span v-else> Non </span>
              </td>
            </tr>
          </tbody>
        </table>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn
          size="md"
          :disabled="sendIdBtn"
          color="primary"
          v-if="!modalUserDetails.content.email_verified_at"
          @click="sendUserIdentifier(modalUserDetails.content.id)"
        >
          <span v-if="!sendIdBtn">Envoyer les identifiants</span>
          <span
            v-if="sendIdBtn"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          <span v-if="sendIdBtn" class="pl-2">Loading...</span>
        </mdb-btn>
        <mdb-btn
          size="md"
          outline="primary"
          @click="modalUserDetails.show = false"
          >Fermer</mdb-btn
        >
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<script>
import ListDesktop from "@/tba/views/users/modules/ListDesktop";
import ListMobile from "@/tba/views/users/modules/ListMobile";
import {
  mdbBtn,
  mdbCol,
  mdbInput,
  mdbModal,
  mdbModalBody,
  mdbModalFooter,
  mdbModalHeader,
  mdbModalTitle,
  mdbRow,
  mdbSelect,
  mdbSwitch,
} from "mdbvue";
import ranString from "randomstring";
export default {
  metaInfo: {
    title: "TECBILL APP",
    titleTemplate: "%s - UTILISATEURS",
    htmlAttrs: {
      lang: "fr",
      amp: true,
    },
  },
  components: {
    mdbBtn,
    mdbSwitch,
    mdbRow,
    mdbCol,
    mdbSelect,
    mdbInput,
    mdbModal,
    mdbModalTitle,
    mdbModalBody,
    mdbModalHeader,
    mdbModalFooter,
    ListDesktop,
    ListMobile,
  },

  data() {
    return {
      dataPagination: {
        pagination: {
          perPage: 10,
          currentPage: 1,
          perPageOptions: [
            { text: "10", value: "10", selected: true },
            { text: "25", value: "25" },
            { text: "50", value: "50" },
            { text: "100", value: "100" },
          ],
          total: 0,
        },
        searchQuery: "",
        propsToSearch: ["firstname", "lastname", "email", "phone", "site"],
      },

      data: {
        columns: [
          {
            label: "Nom & Prénoms",
            field: "name",
            sort: true,
          },
          {
            label: "Adresse Email",
            field: "email",
            sort: true,
          },
          {
            label: "Téléphone",
            field: "phone",
            sort: true,
          },
          {
            label: "Site",
            field: "site",
            sort: true,
          },
          {
            label: "",
            field: "action",
            sort: true,
          },
        ],
        rows: [],
      },
      sendIdBtn: false,
      empty: false,
      loading: false,
      roles: [],
      roleOption: [],
      roleNOption: [
        {
          text: "Administrateur Natif",
          value: "admin",
        },
        {
          text: "Utilisateur Natif",
          value: "user",
          selected: true,
        },
      ],
      sites: [],
      siteOption: [],
      modeOption: [
        {
          text: "Mode Simple",
          value: "simple",
        },
        {
          text: "Mode Avancé",
          value: "advanced",
          selected: true,
        },
      ],
      form: {
        id: "",
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        password: "",
        password_c: "",
        site_id: "",
        password_default: "",
        is_active: true,
        is_approval_credit_plafond: false,
        is_archived: false,
        role: "user",
        roles: [],
        mode: "advanced",
      },

      modalUserDetails: {
        show: false,
        edit: false,
        title: "Détails de l'utilisateur",
        content: {},
      },

      modalUser: {
        show: false,
        edit: false,
        title: "Ajouter un utilisateur",
        content: {},
        btn: false,
      },
    };
  },

  methods: {
    //Fonction de fonctionnement
    addOrEdit(data = null) {
      if (data instanceof Object) {
        this.modalUser.title = "Modifier un utilisateur";
        this.modalUser.content = data;
        this.modalUser.edit = true;
        this.formRest(data);
      } else {
        this.modalUser.title = "Ajouter un utilisateur";
        this.modalUser.content = {};
        this.modalUser.edit = false;
        this.cleanForm("user");
      }

      this.modalUser.show = true;
    },

    searchBack(e) {
      this.dataPagination.searchQuery = e;
    },

    async loadSites() {
      this.$nprogress.start();
      await this.$store
        .dispatch("site/findAll")
        .then((response) => {
          this.$nprogress.done();
          this.sites = response.data.sites;
          this.sites.forEach((site) => {
            this.siteOption.push({
              text: site.name,
              value: site.id,
            });
          });
        })
        .catch((error) => {
          this.$nprogress.done();
          this.$notify({
            message: error.response.data.message || error.response.data.error,
            type: "danger",
          });
        });
    },

    getSite(value) {
      this.form.site_id = value;
    },

    getMode(value) {
      this.form.mode = value;
    },

    getRoleN(value) {
      this.form.role = value;
    },

    getRole(value) {
      this.form.roles = value;
    },

    cleanForm(type) {
      if (type === "user") {
        this.form.id = "";
        this.form.firstname = "";
        this.form.lastname = "";
        this.form.role = "user";
        this.form.roles = [];
        this.form.mode = "advanced";
        this.form.email = "";
        this.form.phone = "";
        this.form.password = "";
        this.form.password_c = "";
        this.form.password_default = "";
        this.form.is_active = true;
        this.form.is_archived = false;
        this.form.is_approval_credit_plafond = false;
      }

      this.roleOption = [];
      this.roles.forEach((role) => {
        this.roleOption.push({
          text: role.name,
          value: role.id,
          selected: false,
        });
      });
    },

    formRest(data) {
      this.form.id = data.id;
      this.form.firstname = data.firstname;
      this.form.lastname = data.lastname;
      this.form.email = data.email;
      this.form.phone = data.phone;
      this.form.site_id = data.site_id;
      this.form.role = data.role;
      this.form.roles = data.roles.map((item) => {
        return item.id;
      });
      this.form.mode = data.mode;
      this.form.is_active = data.is_active === 1 ? true : false;
      this.form.is_archived = data.is_archived === 1 ? true : false;
      this.form.is_approval_credit_plafond =
        data.is_approval_credit_plafond === 1 ? true : false;

      this.siteOption = [];
      this.modeOption = [];
      this.roleNOption = [];
      this.roleOption = [];

      this.sites.forEach((site) => {
        this.siteOption.push({
          text: site.name,
          value: site.id,
          selected: site.id === data.site_id ? true : false,
        });
      });

      this.modeOption.push({
        text: "Mode Simple",
        value: "simple",
        selected: data.mode === "simple" ? true : false,
      });

      this.modeOption.push({
        text: "Mode Avancé",
        value: "advanced",
        selected: data.mode === "advanced" ? true : false,
      });

      this.roleNOption.push({
        text: "Administrateur Natif",
        value: "admin",
        selected: data.role === "admin" ? true : false,
      });

      this.roleNOption.push({
        text: "Utilisateur Natif",
        value: "user",
        selected: data.role === "user" ? true : false,
      });

      this.roles.forEach((role) => {
        this.roleOption.push({
          text: role.name,
          value: role.id,
          selected:
            this.form.roles.filter((item) => item == role.id).length === 1,
        });
      });
    },

    async archivedAlert(e) {
      e.preventDefault();
      if (!this.form.is_archived) {
        let res = await this.$store.dispatch("swal/ultimate", {
          title: "Etes-vous sûre?",
          message:
            "En archivant cet utilisateur, vous mettez fin à ses activités. Notez que cette action est irréversible.",
          type: "warning",
          icon: "warning",
        });

        if (res.isDismissed) {
          return;
        }

        this.form.is_archived = true;

        return;
      } else {
        this.form.is_archived = false;

        return;
      }
    },

    details(row) {
      this.modalUserDetails.content = row;

      this.modalUserDetails.title = `${row.firstname} ${row.lastname}`;

      this.modalUserDetails.show = true;
      //
    },

    getIsActiveValue() {
      //
    },

    async loadUsers() {
      this.$nprogress.start();
      this.loading = !this.loading;
      await this.$store
        .dispatch("user/findAll")
        .then((response) => {
          this.$nprogress.done();
          this.loading = !this.loading;
          if (response.data.users.length === 0) {
            this.empty = true;
          } else {
            this.empty = false;
          }
          this.data.rows = response.data.users;
        })
        .catch((error) => {
          this.$nprogress.done();
          this.loading = !this.loading;
          this.$notify({
            message: error.response.data.message || error.response.data.error,
            type: "danger",
          });
        });
    },

    async loadRoles() {
      this.$nprogress.start();
      this.loading = !this.loading;
      await this.$store
        .dispatch("role/findAll")
        .then((response) => {
          this.$nprogress.done();
          this.loading = !this.loading;
          this.roles = response.data.roles;
          this.roles.forEach((role) => {
            this.roleOption.push({
              text: role.name,
              value: role.id,
            });
          });
        })
        .catch((error) => {
          this.$nprogress.done();
          this.loading = !this.loading;
          this.$notify({
            message: error.response.data.message || error.response.data.error,
            type: "danger",
          });
        });
    },

    async save() {
      this.$store.commit("user/SET_CLEAN");
      this.$nprogress.start();
      this.modalUser.btn = !this.modalUser.btn;
      let pass = ranString.generate(8);
      this.form.password = pass.toUpperCase();
      this.form.password_c = pass.toUpperCase();
      this.form.password_default = pass.toUpperCase();
      await this.$store
        .dispatch("user/save", this.form)
        .then((response) => {
          this.$nprogress.done();
          this.modalUser.show = false;
          this.modalUser.btn = !this.modalUser.btn;
          this.$notify({
            message: response.data.message,
            type: "success",
          });
          this.cleanForm("user");
          this.loadUsers();
        })
        .catch((error) => {
          this.$nprogress.done();
          this.modalUser.btn = !this.modalUser.btn;
          if (error.response.data.errors) {
            this.$store.commit(
              "user/SET_LNAME",
              error.response.data.errors.lastname
            );
            this.$store.commit(
              "user/SET_LFIRST",
              error.response.data.errors.firstname
            );
            this.$store.commit(
              "user/SET_EMAIL",
              error.response.data.errors.email
            );
            this.$store.commit(
              "user/SET_PWD",
              error.response.data.errors.password
            );
            this.$store.commit(
              "user/SET_PWDC",
              error.response.data.errors.password_c
            );
          }
          this.$notify({
            message: error.response.data.message || error.response.data.error,
            type: "danger",
          });
        });
    },

    async update() {
      this.$nprogress.start();
      this.$store.commit("user/SET_CLEAN");
      this.modalUser.btn = !this.modalUser.btn;
      await this.$store
        .dispatch("user/update", this.form)
        .then((response) => {
          this.$nprogress.done();
          this.modalUser.show = false;
          this.modalUser.btn = !this.modalUser.btn;
          this.$notify({
            message: response.data.message,
            type: "success",
          });
          this.cleanForm("user");
          this.loadUsers();
        })
        .catch((error) => {
          this.$nprogress.done();
          this.modalUser.btn = !this.modalUser.btn;
          if (error.response.data.errors) {
            this.$store.commit(
              "user/SET_LNAME",
              error.response.data.errors.lastname
            );
            this.$store.commit(
              "user/SET_LFIRST",
              error.response.data.errors.firstname
            );
            this.$store.commit(
              "user/SET_EMAIL",
              error.response.data.errors.email
            );
          }
          this.$notify({
            message: error.response.data.message || error.response.data.error,
            type: "danger",
          });
        });
    },

    async deleteUser(id) {
      let res = await this.$store.dispatch("swal/doYouDelete");
      if (res.isDismissed) {
        return;
      }

      this.$nprogress.start();
      await this.$store
        .dispatch("user/delete", id)
        .then((response) => {
          this.$nprogress.done();
          this.$notify({
            message: response.data.message,
            type: "success",
          });
          this.loadUsers();
        })
        .catch((error) => {
          this.$nprogress.done();
          this.$notify({
            message: error.response.data.message || error.response.data.error,
            type: "danger",
          });
        });
    },

    async sendUserIdentifier(id) {
      this.$nprogress.start();
      this.sendIdBtn = !this.sendIdBtn;
      await this.$store
        .dispatch("user/sendIdentifier", { id: id })
        .then((response) => {
          this.$nprogress.done();
          this.sendIdBtn = !this.sendIdBtn;
          this.$notify({
            message: response.data.message,
            type: "success",
          });
          this.modalUserDetails.show = false;
          this.loadUsers();
        })
        .catch((error) => {
          this.$nprogress.done();
          this.sendIdBtn = !this.sendIdBtn;
          this.$notify({
            message: error.response.data.message || error.response.data.error,
            type: "danger",
          });
        });
    },

    // Systeme de datatable
    totalCalcul() {
      return (this.dataPagination.pagination.total = this.data.rows.length);
    },

    searchQ() {
      if (!this.dataPagination.searchQuery) {
        // return this.pagedData;
        return new Error("Nothing To Search");
      }

      let result = this.data.rows.filter((row) => {
        let isIncluded = false;
        for (let key of this.dataPagination.propsToSearch) {
          let rowValue = "";
          if (typeof row[key] === "object") {
            switch (key) {
              case "site":
                rowValue = row[key]
                  ? row[key].name.toString().toLowerCase()
                  : "";
                break;
              default:
                break;
            }
          }

          if (typeof row[key] === "string") {
            rowValue = row[key].toString().toLowerCase();
          }
          if (
            rowValue.includes &&
            rowValue.includes(this.dataPagination.searchQuery.toLowerCase())
          ) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      this.dataPagination.pagination.total = result.length;
      return result.slice(this.from, this.to);
    },

    nbrPerPageSelected(value) {
      let nbr = value;

      return this.nbrPerPage(nbr);
    },

    nbrPerPage(nbr) {
      return (this.dataPagination.pagination.perPage = nbr);
    },
  },

  created() {
    this.loadUsers();
    this.loadSites();
    this.loadRoles();
  },

  computed: {
    pageData() {
      return this.data.rows.slice(this.from, this.to);
    },

    to() {
      let highBound = this.from + this.dataPagination.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },

    queriedData() {
      let result = this.searchQ();
      return result instanceof Error ? this.pageData : result;
    },

    from() {
      return (
        this.dataPagination.pagination.perPage *
        (this.dataPagination.pagination.currentPage - 1)
      );
    },

    total() {
      return this.totalCalcul();
    },
  },
};
</script>
